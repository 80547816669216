import { axiosErrorToast } from '@campxdev/shared'
import { toast } from 'react-toastify'

import {
  UpiLogo,
  creditCardIcon,
  debitCardIcon,
  mobileBankingIcon,
} from 'assets/images'
import { Store } from 'pullstate'
import axios from 'utils/axios'

const updateFormResponseStatus = ({
  formResponseId,
  razorPayDetails,
}: {
  formResponseId: string
  razorPayDetails?: any
}) => {
  return axios.post(`/square/forms/${formResponseId}/update-status`, {
    razorPayDetails: razorPayDetails,
  })
}

const submitResponse = ({
  formId,
  responseData,
  razorPayDetails,
}: {
  formId: string
  responseData: any
  razorPayDetails?: any
}) => {
  return axios.post(`/square/forms/${formId}/responses`, {
    responseData: responseData,
    razorPayDetails: razorPayDetails,
  })
}

type CreateOrder = {
  amount: number
  examMode: string
  redirectUrl: string
  leadId: string
  examSlot: string
  paymentMethod: string
  examDate: string
  isWeb: Boolean
  examCenter?: String
}
export type RazorPayOptions = {
  amount: number
  currency: string
  orderId: string
  description: string
  email?: string
  mobile?: string
  fullName?: string
  method: string
  cardType: string | undefined
  clientLogo: string | null
  rzyPayKey: string
}

export const initiateRazorPay = async (
  options: RazorPayOptions,
  onSuccess: (response) => void,
) => {
  let razorpay
  try {
    const razorPayOptions = {
      key: options?.rzyPayKey ?? '',
      amount: options?.amount,
      currency: options?.currency,
      order_id: options?.orderId,
      description: options?.description,
      image: options?.clientLogo ?? '',
      email: options?.email || '',
      contact: options?.mobile || '',
      type: options?.cardType || undefined,
      prefill: {
        name: options?.fullName || '',
        email: options?.email || '',
        contact: options?.mobile || '',
      },
      method: {
        netbanking: options.method === 'netbanking',
        card:
          options.method === 'debit_card' || options.method === 'credit_card',
        wallet: options.method === 'wallet',
        upi: options.method === 'upi',
      },
      handler: async function (response) {
        onSuccess(response)
      },
    }
    razorpay = new (window as any).Razorpay(razorPayOptions)
    razorpay.open()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
  razorpay?.on('payment.failed', function (response) {
    toast.error(response?.error?.description ?? 'Payment Failed')
  })
}

export const paymentMethods = [
  {
    label: 'Net Banking',
    value: 'netbanking',
    cardType: undefined,
    icon: mobileBankingIcon,
  },
  {
    label: 'Debit Card',
    value: 'debit_card',
    cardType: 'debit',
    icon: debitCardIcon,
  },
  {
    label: 'Credit Card',
    value: 'credit_card',
    cardType: 'credit',
    icon: creditCardIcon,
  },
  { label: 'UPI', value: 'upi', cardType: undefined, icon: UpiLogo },
]

export const createBillDeskOrder = async (payload: CreateOrder) => {
  try {
    const res = await axios.post(
      `/paymentx/online-transactions/initiate-lead-ats-payment`,
      payload,
    )
    return res.data
  } catch (error) {
    axiosErrorToast(error)
  }
}

export const formStore = new Store({
  active: 0,
  examMode: null,
  paymentMethod: null,

  examDate: null,
  formValidated: false,
  redirectUrl: null,
  examSlot: null,
  examCenter: null,
})

export { submitResponse, updateFormResponseStatus }
