import { Permission } from '@campxdev/shared'
import AdmissionsForm from 'pages/AdmissionsForm/AdmissionsForm'
import HallTicketPage from 'pages/HallTicketPage/HallTicketPage'
import InitiatePayment from 'pages/InitiatePayment'
import LandingPage from 'pages/LandingPage'
import Thankyou from 'pages/Thankyou'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const ApplyProgram = lazy(() => import('pages/ApplyProgram/ApplyProgram'))
const HomePage = lazy(() => import('pages/HomePage'))
const MyQueries = lazy(() => import('pages/MyQueries/MyQueries'))
const MyProfile = lazy(() => import('pages/MyProfile'))
const TimeSlot = lazy(() => import('pages/TimeSlote'))

export const baseRoutes = [
  // {
  //   index: true,
  //   element: <HomePage />,
  //   permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },

  // {
  //   path: 'apply-program',
  //   element: <ApplyProgram />,
  //   permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },
  {
    index: true,
    element: <Navigate to="/home" />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: '/home',
    element: <LandingPage />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'apply-program',
    element: <ApplyProgram />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'my-profile/:id/time-slot',
    element: <TimeSlot />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'my-profile/:id/payment-status/:orderId',
    element: <InitiatePayment />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'my-profile/:id',
    element: <MyProfile />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'my-profile/:id/hall-ticket',
    element: <HallTicketPage />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'my-queries',
    element: <MyQueries />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'thank-you',
    element: <Thankyou />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'admission-form/:id',
    element: <AdmissionsForm />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
