import {
  FormSingleSelect,
  FormTextField as SharedTextField,
  axiosErrorToast,
} from '@campxdev/shared'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'utils/axios'
import FormTextField from '../../components/TextField'
import { StyledFormSection } from './styles'

const isDevelopment = process.env.NODE_ENV === 'development'

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '60px',
  fontSize: '18px',
}))

export const StyledAuthButtons = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '40px',
  fontSize: '18px',
}))

export const StyledSelectField = styled(FormSingleSelect)(({ theme }) => ({
  '& .MuiInputBase-root': {
    minHeight: '60px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '18.5px 14px',
  },
}))

export const StyledTextField = styled(FormTextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    minHeight: '60px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '18.5px 14px',
  },
}))

export default function EmailLogin() {
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, control } = useForm()
  const [error, setError] = useState('')

  const onSubmit = async (values) => {
    try {
      const res = axios
        .post(`/auth-server/auth/lead-login`, values)
        .then((res) => {
          Cookies.set('campx_lead_tenant', res?.data?.subDomain)
          Cookies.set('campx_lead_key', res?.data?.access_token)
          window.location.href = window.location.origin + `/apply-program`
        })
        .catch((err) => {
          axiosErrorToast(err)
        })
    } catch (err) {
      setError(err.response.data.message ?? 'Server Error')
    }
  }

  return (
    <>
      <StyledFormSection>
        <Box width={'100%'} marginTop="20px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={'20px'}>
              <Box>
                <Typography variant="h6" textAlign={'center'}>
                  Welcome to Giet university Admissions Portal
                </Typography>
                <Typography variant="subtitle2" textAlign={'center'}>
                  Sign in to your account or Create a new account
                </Typography>
              </Box>
              <SharedTextField
                size="medium"
                control={control}
                name="username"
                label="Username/Email"
                required
              />
              <SharedTextField
                control={control}
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledAuthButtons type="submit" fullWidth>
                Login
              </StyledAuthButtons>
            </Stack>
          </form>
          {error && (
            <Alert severity="error" sx={{ marginTop: '20px' }}>
              {error}
            </Alert>
          )}

          <Typography variant="h6" textAlign="center" margin="20px 0">
            or
          </Typography>
          <StyledOutlinedButton
            variant="outlined"
            type="submit"
            fullWidth
            color="secondary"
            onClick={() => (window.location.href = 'register')}
          >
            Create an account
          </StyledOutlinedButton>
        </Box>
      </StyledFormSection>
    </>
  )
}

export const StyledOutlinedButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '60px',
  fontSize: '18px',

  [theme.breakpoints.down('md')]: {
    borderColor: '#C02633',
    color: '#C02633',
  },
}))
