import {
  ErrorBoundary,
  PermissionsStore,
  Spinner,
  useHistory,
} from '@campxdev/shared'
import { styled } from '@mui/material'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { Outlet, useNavigate } from 'react-router-dom'
import { LeadStore } from 'store/LeadStore'
import axios from 'utils/axios'

const headerHeight = '64px'

export const StyledHeaderContainer = styled('header')(() => ({
  zIndex: 300,
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  minHeight: headerHeight,
  background: '#fff',
  boxShadow: '0px 0px 3px #0000001a',
  '& > div': {
    height: headerHeight,
  },
}))

export default function AppLayout() {
  const history = useHistory()

  const navigate = useNavigate()

  const { data: branches, isLoading: branchloading } = useQuery(
    'branches',
    () => axios.get('/paymentx/domain/programs').then((res) => res.data),
  )

  const { data, isLoading } = useQuery(
    'app-init',
    () => axios.get('/square/assets').then((res) => res.data),

    {
      onSuccess(data) {
        LeadStore.store.update((s) => {
          s.logo = data?.logo
          s.logoSquare = data?.school?.logoSquare
          s.leadData = data?.lead
          s.leadActivity = data?.leadActivity
          s.leadTasks = data?.leadTasks
          s.clientName = data?.school?.name
          s.branchDetails = branches?.find(
            (branch: any) => branch?.id == data?.lead?.choice1,
          )
        })
        PermissionsStore.update((s) => {
          s.permissions = {
            can_dashboard_view: 1,
          } as any
        })
      },
      onError(err: AxiosError) {
        if (err?.response?.status >= 401) {
          navigate('home')
        }
      },
    },
  )

  if (isLoading) return <Spinner />
  return (
    <>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}
