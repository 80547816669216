import { withRouteWrapper } from '@campxdev/shared'
import AppLayout from 'layouts/AppLayout'

import { baseRoutes } from './baseRoutes'

export const mainRoutes = [
  {
    path: '/',
    element: <AppLayout />,

    children: withRouteWrapper(baseRoutes),
  },

  // {
  //   path: '/login',
  //   element: withSuspense(<LoginPage />),
  //   children: withRouteWrapper(baseRoutes),
  // },

  // {
  //   path: '/register',
  //   element: <RegisterPage />,
  // },

  {
    path: '*',
    element: (
      <h1 style={{ textAlign: 'center', marginTop: '50px' }}>Page Not Found</h1>
    ),
  },
]
