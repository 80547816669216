import { ActionButton, TextField, axiosErrorToast } from '@campxdev/shared'
import { Stack } from '@mui/material'
import { StyledAuthButtons } from 'pages/AuthPages/EmailLogin'
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import axios from 'utils/axios'
import * as yup from 'yup'

const schema = yup.object().shape({
  otp: yup.string().required('Otp is required'),
})

function EmailVerification({ setRender, setId, Id }) {
  const queryClient = useQueryClient()
  const [verifyJwt, setVerifyJwt] = useState(null)
  const [otp, setOtp] = useState(null)
  const [email, setEmail] = useState(null)
  const [verified, setVerify] = useState(false)
  const { data, isLoading } = useQuery(
    'email-otp',
    async () => {
      const response = await axios.post(
        '/paymentx/enroll-otp/student/lead-email-request-otp',
        {
          email: email,
        },
      )
      toast.success('An OTP has been sent to your given Email.')
      setVerifyJwt(response.data.token)
      return response.data // Assuming you want to return some data from the query
    },
    {
      enabled: verified,
    },
  )

  //   const { data, isLoading } = useQuery('email-otp', async () => {
  //     const response = await axios.post(
  //       '/paymentx/enroll-otp/student/lead-email-request-otp',
  //       {
  //         email: email,
  //       },
  //     )
  //     toast.success('An OTP has been sent to your given Email.')
  //     setVerifyJwt(response.data.token)
  //     return response.data // Assuming you want to return some data from the query
  //   })

  const handleEmailVerification = () => {
    axios
      .post('/paymentx/lead-ats/email-verified', {
        leadId: Id,
      })
      .then((res) => {
        setRender('password')
        queryClient.invalidateQueries('student-details')
        toast.success('Email Verified Successfully')
      })
      .catch((err) => {
        axiosErrorToast(err)
      })
  }

  const onSubmit = () => {
    axios
      .post('/paymentx/enroll-otp/student/verify-otp', {
        otp: otp,
        token: verifyJwt,
      })
      .then((res) => {
        handleEmailVerification()
        close()
      })
      .catch((err) => {
        axiosErrorToast(err)
      })
  }

  const handleVerification = () => {
    if (!email) {
      toast.error('Enter Your Registered Email')
      return
    }
    axios
      .post('/paymentx/leads/lead-by-email', {
        email: email,
      })
      .then((res) => {
        setId(res.data.id)
        setVerify(true)
        // handleEmailVerification()
      })
      .catch((err) => {
        axiosErrorToast(err)
      })
  }
  return (
    <div>
      <>
        {verified ? (
          <OtpComponent otp={otp} setOtp={setOtp} onSubmit={onSubmit} />
        ) : (
          <Stack gap={4}>
            <TextField
              name="email"
              required
              value={email}
              label={'Enter Registered Email '}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <StyledAuthButtons fullWidth onClick={handleVerification}>
              Verify
            </StyledAuthButtons>
          </Stack>
        )}
      </>
    </div>
  )
}

export default EmailVerification

const OtpComponent = ({ otp, setOtp, onSubmit }) => {
  return (
    <Stack gap={4}>
      <TextField
        name="otp"
        required
        value={otp}
        label={'Enter Email Otp'}
        onChange={(e) => {
          setOtp(e.target.value)
        }}
      />
      <Stack gap={2} direction={'row'}>
        <ActionButton variant="outlined" onClick={close} fullWidth>
          Cancel
        </ActionButton>
        <ActionButton type="submit" fullWidth onClick={onSubmit}>
          Submit
        </ActionButton>
      </Stack>
    </Stack>
  )
}
