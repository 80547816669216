import { Spinner, useExternalScript } from '@campxdev/shared'
import { Box, Button, Typography, styled } from '@mui/material'
import { formStore } from 'pages/TimeSlote/Steps/service'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'utils/axios'
import {
  TimelineStepStatus,
  acknowledgePayment,
  billDeskModes,
  getAuthToken,
  initiateBillDeskConfig,
  studentPaymentStore,
  verfiyOrder,
} from './service'

function InitiatePayment() {
  const { orderId, id } = useParams()

  const navigate = useNavigate()
  const payment = studentPaymentStore.useState()
  const [loading, setLoading] = useState(true)
  const [billDesk, setBillDesk] = useState(false)
  const [airPay, setAirPay] = useState(false)
  const getPayments = (data) => {
    return data?.order?.notes?.feeTypes
      ?.filter((item) => item?.amount && item.amount > 0)
      ?.map((item) => {
        return {
          amount: item.amount,
          type: item.feeType,
          year: data?.order?.notes?.year,
        }
      })
  }

  const url = `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/${payment.rzyPayKey}.js`

  const status = useExternalScript(url)

  const {
    data: verifyData,
    isLoading: verifyLoading,
    isFetchedAfterMount,
  } = useQuery('verify-order', () => verfiyOrder(orderId), {
    onSuccess(data) {
      studentPaymentStore.update((s) => {
        s.payable = data.order.amount
        s.rzrPayRes = data
        s.totalPayable = data.order.amount + data.order.charges
        s.timelineStatus = data.status
        s.paymentMethod = data.paymentMethod
        s.timelineStep = TimelineStepStatus[data.status]
        s.type = data.paymentClientType
        s.year = data.order?.notes?.year
        s.airpayCredentials = data?.credentials?.airPayConfig
        s.billDeskCredentials = {
          msg: data?.order?.orderResponse?.msg,
          paymentUrl: 'https://pgi.billdesk.com/pgidsk/PGIMerchantPayment',
        }
        s.billDeskV2Data = data?.order?.orderResponse
        s.paymentData = getPayments(data)
        s.admission = data?.admission
      })

      if (data.status == 'PAYMENT_FAILED') {
        toast.error('payment failed')
      }
    },
  })

  const handlePayment = async (options?: any) => {
    try {
      // if (!payment.rzrPayRes) {
      //   toast.error("Couldn't Find Order Details")
      //   return
      // }

      const onSuccess = async (rzPayRes) => {
        const data = await axios
          .get('/paymentx/online-transactions/verify-order', {
            params: {
              orderId: payment.rzrPayRes.orderId,
            },
          })
          .then(async (res) => {
            if (res.data.status == 'PAYMENT_DONE') {
              studentPaymentStore.update((s) => {
                s.timelineStep = 2
                s.timelineStatus = 'PAYMENT_DONE'
              })

              await acknowledgePayment(
                rzPayRes,
                id,
                payment.type,

                {
                  amount: payment.rzrPayRes.order.amount,
                  feeTypes: payment.rzrPayRes.order.notes.feeTypes,
                  admissionId: payment.rzrPayRes.admissionId,
                  paymentMethod: payment.rzrPayRes.paymentMethod,
                  year: payment.year,
                },
                navigate,
              )
            }
            if (res.data.status == 'PAYMENT_FAILED') {
              studentPaymentStore.update((s) => {
                s.timelineStep = 1
                s.timelineStatus = 'PAYMENT_FAILED'
              })
            }
          })
      }

      if (payment.type == 'BILL_DESK_V2') {
        await initiateBillDeskConfig(options, onSuccess, setLoading)
        return null
      }
    } catch (err) {
      toast.error('Failed to initiate payment')
    }
  }

  useEffect(() => {
    if (
      verifyData &&
      payment.rzrPayRes &&
      isFetchedAfterMount &&
      status != 'loading'
    ) {
      if (
        verifyData.status == 'IN_PROGRESS' ||
        verifyData.status == 'INITIATED'
      ) {
        var flow_config = {
          merchantId: verifyData?.order?.orderResponse?.mercid,
          bdOrderId: verifyData?.order?.orderResponse?.bdorderid,
          authToken: getAuthToken(verifyData?.order?.orderResponse),
          childWindow: true,
          returnUrl: verifyData?.order?.orderResponse?.ru,
          retryCount: 3,
          prefs: {
            payment_categories: [billDeskModes[verifyData?.paymentMethod]],
            // allowed_bins: ['459150', '525211'],
          },
          netBanking: {
            showPopularBanks: 'Y',
            popularBanks: [
              'Kotak Bank',
              'AXIS Bank [Retail]',
              'State Bank of India',
            ],
          },
        }

        switch (verifyData.paymentClientType) {
          case 'BILL_DESK_V2':
            handlePayment(flow_config)
            break

          default:
            handlePayment()
            break
        }
      }

      if (verifyData.status == 'PAYMENT_DONE') {
        acknowledgePayment(
          verifyData,
          id,
          verifyData.paymentClientType,
          {
            amount: verifyData.order.amount,
            feeTypes: verifyData.order.notes.feeTypes,
            admissionId: verifyData.admissionId,
            paymentMethod: verifyData.paymentMethod,
            year: verifyData.order.notes.year,
          },
          navigate,
        )
      }
    }
  }, [isFetchedAfterMount, status])

  if (verifyLoading) return <Spinner />

  if (billDesk) return <BillDeskForm data={payment.billDeskData} />

  if (airPay) return <AirPayForm data={payment.airpayData} />

  return (
    <>
      <StyledResponseMessage>
        <RenderStep2
          status={payment.timelineStatus}
          loading={loading}
          id={id}
        />
      </StyledResponseMessage>
    </>
  )
}

export const RenderStep2 = ({ status, loading, id }) => {
  const navigate = useNavigate()
  const { type, airpayData, billDeskData, rzrPayRes } =
    studentPaymentStore.useState()

  switch (status) {
    case 'INITIATED':
      if (loading) {
        return <Spinner />
      } else {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6" textAlign={'center'}>
              Payment Intiated But not Completed
            </Typography>

            <Button onClick={() => navigate(`/my-profile/${id}/time-slot`)}>
              {' '}
              Retry Payment
            </Button>
          </Box>
        )
      }

    case 'IN_PROGRESS':
      if (type == 'AirPay') {
        return <AirPayForm data={airpayData} />
      }
      if (type == 'BILL_DESK') {
        return <BillDeskForm data={billDeskData} />
      } else {
        return (
          <Typography variant="h6" textAlign={'center'}>
            Processing Payment
          </Typography>
        )
      }

    case 'PAYMENT_DONE':
      return <PaymentDoneStatus status={status} />

    case 'SUCCESS':
      return <PaymentDoneStatus status={status} />

    case 'PAYMENT_FAILED':
      return (
        <Box sx={{ flexDirection: 'column', gap: 2 }}>
          <Typography variant="h6" textAlign={'center'}>
            Payment Failed
          </Typography>

          <Button onClick={() => navigate(-2)}> Retry Payment</Button>
        </Box>
      )

    case 'FAILED':
      return (
        <Box sx={{ flexDirection: 'column', gap: 2 }}>
          <Typography variant="h6" textAlign={'center'}>
            Payment Failed
          </Typography>

          <Button onClick={() => navigate(-1)}> Retry Payment</Button>
        </Box>
      )

    default:
      return (
        <>
          <Typography variant="h6" sx={{ marginBottom: '10px' }}>
            Payment Processing
          </Typography>
          <Spinner />
          <Button
            onClick={() => {
              navigate(`/my-profile/${id}/time-slot`)
            }}
          >
            {' '}
            Retry Payment
          </Button>
        </>
      )
  }
}

export default InitiatePayment

export const BillDeskForm = ({ data }) => {
  const billDeskRef = useRef(null)
  useEffect(() => {
    if (data) {
      billDeskRef.current.submit()
    }
  }, [data])

  return (
    <>
      <form
        method="POST"
        action={
          data?.url ?? 'https://pgi.billdesk.com/pgidsk/PGIMerchantPayment'
        }
        ref={billDeskRef}
        name="billdesk"
      >
        <input name="msg" type="hidden" value={data?.msg} />
      </form>
    </>
  )
}

export const AirPayForm = ({ data }) => {
  const formRef = useRef(null)
  useEffect(() => {
    formRef.current.submit()
  }, [])

  return (
    <>
      <form
        method="POST"
        action="https://payments.airpay.co.in/pay/index.php"
        ref={formRef}
      >
        <div className="form-group">
          <input
            id="privatekey"
            className="form-control"
            type="hidden"
            name="privatekey"
            value={data?.privatekey}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerPhone"
            className="form-control"
            type="hidden"
            name="mercid"
            value={data?.mid}
          />
        </div>
        <div className="form-group">
          <input
            id="orderid"
            className="form-control"
            type="hidden"
            name="orderid"
            value={data?.data?.orderid}
          />
        </div>
        <div className="form-group">
          <input
            id="currency"
            className="form-control"
            type="hidden"
            name="currency"
            value="356"
          />
        </div>
        <div className="form-group">
          <input
            id="isocurrency"
            className="form-control"
            type="hidden"
            name="isocurrency"
            value="INR"
          />
        </div>
        <div className="form-group">
          <input
            id="chmod"
            className="form-control"
            type="hidden"
            name="chmod"
            value=""
          />
        </div>
        <div className="form-group">
          <input
            id="buyerEmail"
            className="form-control"
            type="hidden"
            name="buyerEmail"
            value={data?.data?.buyerEmail}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerPhone"
            className="form-control"
            type="hidden"
            name="buyerPhone"
            value={data?.data?.buyerPhone}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerFirstName"
            className="form-control"
            type="hidden"
            name="buyerFirstName"
            value={data?.data?.buyerFirstName}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerLastName"
            className="form-control"
            type="hidden"
            name="buyerLastName"
            value={data?.data?.buyerLastName}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerAddress"
            className="form-control"
            type="hidden"
            name="buyerAddress"
            value={data?.data?.buyerAddress}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerCity"
            className="form-control"
            type="hidden"
            name="buyerCity"
            value={data?.data?.buyerCity}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerState"
            className="form-control"
            type="hidden"
            name="buyerState"
            value={data?.data?.buyerState}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerCountry"
            className="form-control"
            type="hidden"
            name="buyerCountry"
            value={data?.data?.buyerCountry}
          />
        </div>
        <div className="form-group">
          <input
            id="buyerPinCode"
            className="form-control"
            type="hidden"
            name="buyerPinCode"
            value={data?.data?.buyerPinCode}
          />
        </div>
        <div className="form-group">
          <input
            id="amount"
            className="form-control"
            type="hidden"
            name="amount"
            value={data?.data?.amount}
          />
        </div>
        <div className="form-group">
          <input
            id="checksum"
            className="form-control"
            type="hidden"
            name="checksum"
            value={data?.checksum}
          />
        </div>
        <div className="form-group">
          <input
            id="txnsubtype"
            className="form-control"
            type="hidden"
            name="txnsubtype"
            value={data?.data?.txnsubtype}
          />
        </div>
        <div className="form-group">
          <input
            id="redirectUrl"
            className="form-control"
            type="hidden"
            name="redirectUrl"
            value={'http://localhost:3000/campx/public/'}
          />
        </div>
      </form>
    </>
  )
}

const PaymentDoneStatus = ({ status }) => {
  const payment = studentPaymentStore.useState()
  const navigate = useNavigate()
  const { rzrPayRes, type, year, paymentStatus, paymentData } =
    studentPaymentStore.useState()

  const { id } = useParams()

  return (
    <StyledResponseMessage>
      <Typography variant="h6" textAlign={'center'}>
        Payment Done
      </Typography>
      <Button
        onClick={() => {
          formStore.update((s) => {
            s.active = 2
          })
          navigate(`/my-profile/${id}`)
        }}
      >
        Back Home
      </Button>
    </StyledResponseMessage>
  )
}

export const StyledResponseMessage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '40vh',
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))
