const welcomeImage = require('./welcomeimage.png')
const campxLogoWhite = require('./campx_logo_white.png')
const WALogo = require('./walogo.png')
const avatarImage = require('./avatar.png')
const stepVerify = require('./verify.png')
const stepSubmit = require('./payment.png')
const stepRegister = require('./g15.png')
const stepLogin = require('./g4449.png')
const stepNotification = require('./g507.png')
const stepFill = require('./application3.png')
const instructionImg = require('./instructions.jpg')
const banner = require('./banner_back.jpg')
const newauBanner = require('./gietbanner.png')
const gietlogo = require('./gietlogo.png')
const UpiLogo = require('./UPILogo3x.png')
const creditCardIcon = require('./credit-card3x.png')
const debitCardIcon = require('./debit-card3x.png')
const mobileBankingIcon = require('./mobile-banking3x.png')
import admissionIcon from './admission.svg'
import applicationIcon from './application.svg'
import exam from './exams.png'
import ggulog from './ggulogo.png'
import importIcon from './import.svg'
import loginIcon from './login.svg'
import registerIcon from './register.svg'
import submitIcon from './submit.svg'
import tickGif from './tick.gif'
export {
  UpiLogo,
  WALogo,
  admissionIcon,
  applicationIcon,
  avatarImage,
  banner,
  campxLogoWhite,
  creditCardIcon,
  debitCardIcon,
  exam,
  ggulog,
  gietlogo,
  importIcon,
  instructionImg,
  loginIcon,
  mobileBankingIcon,
  newauBanner,
  registerIcon,
  stepFill,
  stepLogin,
  stepNotification,
  stepRegister,
  stepSubmit,
  stepVerify,
  submitIcon,
  tickGif,
  welcomeImage,
}
