import { Box, Button, Typography, styled } from '@mui/material'
import { ggulog } from 'assets/images'
import moment from 'moment'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import test from 'styled-components'
import axios from 'utils/axios'
let StyledReceipt = test.div<{ textOnly: false }>`
* {
-webkit-print-color-adjust: exact !important;
color-adjust: exact !important;
}

@media print{
  a::after {
    content: none !important;
  }

  @page {
    margin: 0;
  }

  .exam_prerequisites {
    page-break-before: always;
    marginY:20px
  }

   
    .print_btn {
      display: none;
    }
  }`
const offilineKeys = [
  'Arrival Time:',
  'Special Attire:',
  'Required Documents:',
  'Hall Ticket:',
  'Adherence to Rules:',
  'Social Distancing:',
  'Seating: ',
  'Stationery:',
  'Rough Work: ',
  'Attendance:',
]
const offlinePrerequisites = [
  `Candidates must arrive at the exam centre at least one hour before the exam and must not leave until the exam ends.`,
  ' Students wearing any special attire due to religious reasons are advised to reach the exam centres earlier for thorough checking and mandatory frisking.',
  ' Candidates must carry the downloaded Hall Ticket 2024, original Aadhaar Card and a copy of signed Adhaar for submission to the exam centre.',
  ' Candidates without the Hall Ticket 2024 will not be permitted to take the examination under any circumstances by the Centre Superintendent.',
  ' Students must strictly adhere to the GGUCET 2024 rules and regulations as instructed by the centre superintendent/invigilators.',
  ' Candidates must maintain social distancing.',
  'Candidates should take their seats immediately after the opening of the examination hall. They can log in and read the instructions before the exam commences.',
  ' Candidates need to bring their own pen for rough work. No electronic gadgets / smart devices are permitted in the exam hall',
  'A blank paper for rough work will be provided in the examination hall. Candidates must write their name and roll number at the top of the sheet. This sheet needs to be returned to the invigilator before leaving the examination hall.',
  ' Candidates need to enter the required details in the attendance sheet and sign it.',
]
const onlinePrerequisites = [
  'Candidates must have access to a laptop with uninterrupted power supply and internet connectivity.',
  'Candidates can take the exam according to their own schedule during the allocated timings.',
  'Exam PC/Laptop with at least 2GB RAM (Recommendable 4GB)',
  'Working Webcam',
  'Working Mic',
  'Internet speed at least 2mbps (Mobile Hotspot not recommended)',
  'Power backup',
  'Computers to be logged in through Admin user only',
  'Updated version of Google Chrome',
]

function HallTicketPage() {
  const { id } = useParams()

  const { data, isLoading } = useQuery('student-details', () => {
    return axios.get(`/paymentx/leads/${id}`).then((res) => res.data)
  })

  const terms =
    data?.onlineTransaction?.submitBody?.leadATSPaymentBody?.examMode ==
    'offline'
      ? offlinePrerequisites
      : onlinePrerequisites

  return (
    <StyledReceipt>
      <Box sx={{ width: '95vw', margin: 'auto', maxWidth: '1250px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}
          className="print_btn"
        >
          <Button size="small" onClick={() => window.print()}>
            Download
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginY: '30px',
          }}
        >
          <img
            src={ggulog}
            alt="logo"
            style={{
              width: '180px',
            }}
          />
        </Box>
        <Box
          sx={{
            background:
              'transparent linear-gradient(91deg, #E21D23 0%, #244695 100%) 0% 0% no-repeat padding-box;',
            height: '20px',
            borderRadius: '10px',
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '30px',
              marginY: '30px',
              '@media(max-width: 980px)': {
                fontSize: '18px',
              },
              '@media(max-width: 680px)': {
                fontSize: '16px',
              },
            }}
          >
            GGUCET 2024 HALL TICKET
          </Typography>
        </Box>
        <Box
          sx={{
            border: '1px solid #0000001A',
            borderRadius: '10px',
          }}
        >
          <TableItem lable={'Name:'} value={data?.fullName} />
          <TableItem lable={'Batch:'} value={'2024 - 2025'} />
          <TableItem lable={'Degree:'} value={data?.course?.courseName} />
          <TableItem lable={'Program:'} value={data?.course?.program} />
          {data?.onlineTransaction?.submitBody?.leadATSPaymentBody?.examMode ==
            'offline' && (
            <>
              {' '}
              <TableItem lable={'Mobile Number:'} value={data?.mobile} />
              <TableItem
                lable={'DOB'}
                value={moment(data?.personalDetails?.dob).format('DD-MM-YYYY')}
              />
            </>
          )}
        </Box>

        <Typography
          sx={{
            marginY: '30px',
            fontSize: '18px',
            '@media(max-width: 980px)': {
              fontSize: '18px',
            },
            '@media(max-width: 680px)': {
              fontSize: '14px',
            },
          }}
          variant="h4"
        >
          EXAM CENTRE DETAILS
        </Typography>
        <Box
          sx={{
            border: '1px solid #0000001A',
            borderRadius: '10px',
          }}
        >
          <TableItem
            lable={'Exam Centre:'}
            value={
              data?.onlineTransaction?.submitBody?.leadATSPaymentBody
                ?.examCenter
            }
          />
          <TableItem
            lable={'Date & Time:'}
            value={`${data?.onlineTransaction?.submitBody?.leadATSPaymentBody?.examDate} &   ${data?.onlineTransaction?.submitBody?.leadATSPaymentBody?.examSlot}`}
          />
        </Box>

        <Box className="exam_prerequisites" sx={{ paddingTop: '20px' }}>
          <Typography variant="h5" sx={{ marginTop: '16px', fontSize: '18px' }}>
            Exam Prerequisites{' '}
          </Typography>
          {terms?.map((point, index) => (
            <StyledPointBox key={index}>
              <StyledPoints />
              {data?.onlineTransaction?.submitBody?.leadATSPaymentBody
                ?.examMode == 'offline' && (
                <Typography
                  variant="h3"
                  sx={{ fontSize: '14px', width: 'auto' }}
                >
                  {' '}
                  {offilineKeys[index]}
                </Typography>
              )}

              <Typography variant="subtitle1">{point}</Typography>
            </StyledPointBox>
          ))}
        </Box>
      </Box>
    </StyledReceipt>
  )
}

export default HallTicketPage

const StyledColorBox = styled(Box)({
  backgroundColor: '#F2F2F2',
  padding: '15px',
  height: '100%',
  minWidth: '150px',
})

const StyledTypography = styled(Typography)({
  fontSize: '18px',
  color: 'rgba(18, 18, 18, 0.7)',
  '@media(max-width: 980px)': {
    fontSize: '16px',
  },
  '@media(max-width: 680px)': {
    fontSize: '14px',
  },
})

const TableItem = ({ lable, value }) => {
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          borderBottom: '1px solid #0000001A',
          gridTemplateColumns: '1fr 4fr',
          '@media(max-width: 680px)': {
            gridTemplateColumns: '2fr 10fr',
          },
        }}
      >
        <StyledColorBox>
          <StyledTypography variant="h4">{lable}</StyledTypography>
        </StyledColorBox>
        <Box sx={{ padding: '15px' }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: '18px',
              '@media(max-width: 980px)': {
                fontSize: '14px',
              },
              '@media(max-width: 680px)': {
                fontSize: '10px',
                textWrap: 'wrap',
              },
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export const StyledPoints = styled(Box)(({ theme }) => ({
  minWidth: '6px',
  height: '6px',
  backgroundColor: '#c12a37',
  borderRadius: '50%',
}))

export const StyledPointBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginTop: '13px',
  flexWrap: 'wrap',
}))
