import { Box, Typography } from '@mui/material'
import { StyledPointBox, StyledPoints } from '../styles'

const programs = [
  {
    name: 'B.Tech.',
    points: [
      'AUTOMOBILE ENGINEERING',
      'CIVIL ENGINEERING',
      'COMPUTER SCIENCE AND ENGINEERING',
      'COMPUTER SCIENCE AND ENGINEERING (CYBER SECURITY)',
      'COMPUTER SCIENCE AND ENGINEERING (DATA SCIENCE)',
      'COMPUTER SCIENCE AND ENGINEERING (ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING)',
      'ELECTRICAL AND ELECTRONICS ENGINEERING',
      'ELECTRONICS & COMMUNICATION ENGINEERING',
      'MECHANICAL ENGINEERING',
      'MINING ENGINEERING',
    ],
  },
  {
    name: 'M.Tech.',
    points: [
      'COMPUTER SCIENCE AND ENGINEERING',
      'COMPUTER AIDED DESIGN AND MANUFACTURE',
      'POWER SYSTEMS',
      'STRUCTURAL ENGINEERING',
      'VLSI AND EMBEDDED SYSTEMS',
    ],
  },
  {
    name: ' MCA - MASTERS OF COMPUTER APPLICATIONS',
    points: [],
  },
  {
    name: ' BCA – BACHELOR OF COMPUTER APPLICATIONS',
    points: [],
  },
  {
    name: ' B.Sc.',
    points: ['Forensic Science', 'Cyber Forensic Science', 'Computer Science'],
  },
  {
    name: 'Ph.D.',
    points: [],
  },
]

function Program() {
  return (
    <Box sx={{ minWidth: '300px' }}>
      <Typography variant="h6" sx={{ fontSize: '32px' }}>
        Programs Offered
      </Typography>
      {programs.map((item, index) => (
        <Box key={index}>
          <Typography variant="h6" sx={{ paddingY: '10px ' }}>
            {item.name}
          </Typography>
          {item.points.map((point, index) => (
            <StyledPointBox key={index}>
              <StyledPoints />
              <Typography sx={{ fontSize: '15px' }}>{point}</Typography>
            </StyledPointBox>
          ))}
        </Box>
      ))}
    </Box>
  )
}

export default Program
