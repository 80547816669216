import { TextField, axiosErrorToast } from '@campxdev/shared'
import { Stack } from '@mui/material'
import { StyledAuthButtons } from 'pages/AuthPages/EmailLogin'
import { useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'utils/axios'

function ChangePassword({ Id, setRender }) {
  const [ChangePassword, setChangePassword] = useState('')

  const [confirmPassword, setConfirmPassword] = useState('')
  const handleVerification = () => {
    if (!ChangePassword) {
      toast.error('Password is Required')
      return
    }
    // if (confirmPassword !== ChangePassword) {
    //   toast.error('Enter both passwords are same')
    //   return
    // }
    axios
      .put(`/paymentx/leads/${Id}/update-password`, {
        password: ChangePassword,
      })
      .then((res) => {
        setRender('login-tab')
        //   setVerify(true)
      })
      .catch((err) => {
        axiosErrorToast(err)
      })
  }
  return (
    <div>
      <Stack gap={4}>
        <TextField
          name="password"
          required
          value={ChangePassword}
          label={'Enter New Password  '}
          onChange={(e) => {
            setChangePassword(e.target.value)
          }}
        />
        {/* <TextField
          name="confirmPassword"
          required
          value={confirmPassword}
          label={'Enter Confirm Password  '}
          onChange={(e) => {
            setConfirmPassword(e.target.value)
          }}
        /> */}
        <StyledAuthButtons fullWidth onClick={handleVerification}>
          Update
        </StyledAuthButtons>
      </Stack>
    </div>
  )
}

export default ChangePassword
