import {
  FormDatePicker,
  FormMultiSelect,
  FormSingleCheckbox,
  FormSingleSelect,
  FormTextField,
  axiosErrorToast,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { stateCity } from 'constants/state-city'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LeadStore } from 'store/LeadStore'
import axios from 'utils/axios'
import * as yup from 'yup'
import { CountryOptions, genderOptions } from './services'
const mobileRegx: any = new RegExp(/^[6-9]\d{9}$/)
const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')

const schema = yup.object().shape({
  // firstName: yup.string().required('First Name is required'),
  // lastName: yup.string().required('Last Name is required'),
  fullName: yup.string().required('Full Name is required'),
  gender: yup.string().required('Gender is required'),
  dob: yup.string().required('DOB is required'),
  mobile: yup
    .string()
    .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
    .nullable()
    .required('mobile is required'),
  interHallTicket: yup.string().required('InterHallTicketNumber is required'),
  email: yup
    .string()
    .required('Email is required')
    .matches(emailRegex, 'Enter a valid E-mail address')
    .nullable(),
  country: yup.string().required('Country is required'),

  courseId: yup.string().required('Degree is required'),
  programId: yup.string().required('Program is required').nullable(),
  password: yup.string().required('Password is required'),

  city: yup.string().when('country', {
    is: (country) => country !== 'Other',
    then: yup.string().required('City is required'),
    otherwise: yup.string().notRequired(),
  }),
  state: yup.string().when('country', {
    is: (country) => country !== 'Other',
    then: yup.string().required('State is required'),
    otherwise: yup.string().notRequired(),
  }),
  newCounty: yup.string().when('country', {
    is: (country) => country == 'Other',
    then: yup.string().required('Enter Country Name'),
    otherwise: yup.string().notRequired(),
  }),
  countryState: yup.string().when('country', {
    is: (country) => country == 'Other',
    then: yup.string().required('State is required'),
    otherwise: yup.string().notRequired(),
  }),
})
export const StyledOverflowBox = styled(Box)(({ theme }) => ({
  maxHeight: '100vh',
  width: '100%',
  overflowY: 'scroll',
  [theme.breakpoints.down('md')]: {
    maxHeight: '200vh',
    overflowY: 'hidden',
  },
}))

function RegisterPage() {
  const searchParams = new URLSearchParams(window.location.search)
  const [captchaResponse, setCaptchaResponse] = useState('')

  const handleCaptchaChange = (response) => {
    setCaptchaResponse(response)
  }

  useEffect(() => {
    if (Cookies.get('campx_lead_tenant')) {
      Cookies.remove('campx_lead_key')
      Cookies.remove('campx_lead_tenant')
    }
    LeadStore.initialState()
  }, [])

  const navigate = useNavigate()

  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  })
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [otpPage, setOtpPage] = useState(false)
  const [otp, setOtp] = useState(null)
  const [mobile, setMobile] = useState(null)
  const [verifyJwt, setVerifyJwt] = useState(null)
  const [postBody, setPostBody] = useState(null)

  const { data: course, isLoading: courseLoading } = useQuery('branch', () =>
    axios.get('/paymentx/domain/courses').then((res) => res.data),
  )
  const { data: program, isLoading: programLoading } = useQuery('program', () =>
    axios.get('/paymentx/domain/programs').then((res) => res.data),
  )
  const courseId = watch().courseId
  const programData = courseId
    ? program?.filter((item) => {
        return item?.courseId === courseId
      })
    : []

  const onSubmit = (formData) => {
    setMobile(formData.mobile)
    setLoading(true)
    const postBody = {
      ...formData,
      firstName: formData.fullName?.split(' ')[0],
      lastName: formData.fullName?.split(' ')?.slice(1).join(' ') || ' ',
      institutionId: 1,
      country:
        formData.country == 'Other' ? formData.newCounty : formData.country,
      state:
        formData.country == 'Other' ? formData.countryState : formData.state,
      stream: formData.stream?.map((item) => item.value),

      utmSource: searchParams.get('utm_source') || 'GGU-CET',
      utmMedium: searchParams.get('utm_medium'),
      utmCampaign: searchParams.get('utm_campaign'),
      utmContent: searchParams.get('utm_content'),
    }

    setPostBody(postBody)

    const otpBody = {
      mobile: formData.mobile,
    }

    axios
      .post('/paymentx/lead-ats/validate-ats-lead', postBody)
      .then((res) => {
        setOtpPage(true)
        handleRequestOtp(otpBody)
      })
      .catch((err) => {
        setLoading(false)
        axiosErrorToast(err)
      })
  }

  function handleRequestOtp(otpBody: any) {
    axios
      .post('/paymentx/enroll-otp/student/lead-request-otp', otpBody)
      .then((res) => {
        toast.success('An OTP has been sent to your given phone number.')
        setVerifyJwt(res.data.token)
      })
  }

  function handleStudentRegister() {
    axios
      .post('/paymentx/lead-ats', postBody)
      .then((res) => {
        setLoading(false)
        Cookies.set('campx_lead_tenant', res?.data?.subDomain)
        Cookies.set('campx_lead_key', res?.data?.access_token)
        navigate(`/my-profile/${res?.data?.leadId}`)
        toast.success(
          'Account Created Successfully. Please login to your account.',
        )
      })
      .catch((err) => {
        setLoading(false)
        axiosErrorToast(err)
      })
  }

  function handleVerifyOtp() {
    axios
      .post('/paymentx/enroll-otp/student/verify-otp', {
        otp: otp,
        token: verifyJwt,
      })
      .then((res) => {
        toast.success('Verified')
        handleStudentRegister()
      })
      .catch((err) => {
        axiosErrorToast(err)
      })
  }

  useEffect(() => {
    setValue('choice1', null)
  }, [courseId])

  function handleResendOTP() {
    const otpBody = {
      mobile: mobile,
    }

    axios
      .post('/paymentx/enroll-otp/student/lead-request-otp', otpBody)
      .then((res) => {
        toast.success('An OTP has been sent to your given phone number.')
        setVerifyJwt(res.data.token)
      })
  }

  return (
    <Box>
      <Box width={'100%'}>
        {otpPage ? (
          <>
            <Stack gap={'12px'}>
              <Typography variant="caption" textAlign={'center'}>
                Enter OTP sent to {mobile}
              </Typography>
              <FormTextField
                size="small"
                control={control}
                name="otp"
                label="Enter OTP"
                required
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button type="submit" onClick={handleVerifyOtp}>
                Verify OTP
              </Button>
            </Stack>
            <Button
              variant="text"
              sx={{ mt: 2 }}
              onClick={() => handleResendOTP()}
            >
              Resend OTP?
            </Button>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={'12px'}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {/* <FormTextField
                    size="small"
                    control={control}
                    name="firstName"
                    label="First Name "
                    required
                    onChange={(e) => {
                      setValue('firstName', e.target.value.toUpperCase())
                    }}
                  />
                  <FormTextField
                    size="small"
                    control={control}
                    name="lastName"
                    label="Last Name"
                    required
                    onChange={(e) => {
                      setValue('lastName', e.target.value.toUpperCase())
                    }}
                  /> */}

                  <FormTextField
                    size="small"
                    control={control}
                    name="fullName"
                    label="Name of the student (as per SSC records) "
                    required
                    onChange={(e) => {
                      setValue('fullName', e.target.value.toUpperCase())
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormSingleSelect
                    size="small"
                    control={control}
                    name="gender"
                    label="Gender"
                    required
                    options={genderOptions}
                  />
                  <FormDatePicker
                    size="small"
                    control={control}
                    name="dob"
                    label="Date of Birth"
                    required
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormTextField
                    size="small"
                    control={control}
                    name="mobile"
                    label="Mobile Number (preferably WhatsApp No.)"
                    required
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormTextField
                    size="small"
                    control={control}
                    name="alternativeMobileNumber"
                    label="Alternate Mobile No. "
                  />
                  <FormTextField
                    size="small"
                    control={control}
                    name="email"
                    label="E-mail"
                    required
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormTextField
                    size="small"
                    control={control}
                    name="interHallTicket"
                    label="Inter HallTicket Number"
                    required
                  />
                  <FormTextField
                    size="small"
                    control={control}
                    name="eamcetHallTicketNumber"
                    label="Eamcet HallTicket Number"
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ width: '40%' }}>
                    <FormSingleSelect
                      control={control}
                      size="small"
                      name="country"
                      label="Country Name"
                      required
                      options={CountryOptions}
                    />
                  </Box>
                  <Box sx={{ width: '60%' }}>
                    {watch('country') !== 'Other' ? (
                      <FormSingleSelect
                        control={control}
                        size="small"
                        name="state"
                        label="State"
                        required
                        options={Object.keys(stateCity)?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                      />
                    ) : (
                      <FormTextField
                        control={control}
                        name="newCounty"
                        label={'Enter Country Name'}
                        required
                        size="small"
                      />
                    )}
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ width: '30%' }}>
                    {watch('country') !== 'Other' ? (
                      <FormSingleSelect
                        control={control}
                        name="city"
                        label="City"
                        size="small"
                        required
                        options={stateCity[watch('state')]?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                      />
                    ) : (
                      <FormTextField
                        control={control}
                        name="countryState"
                        label={'Enter State Name'}
                        required
                        size="small"
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      input: { minHeight: '30px !important' },
                      width: '70%',
                    }}
                  >
                    <FormMultiSelect
                      control={control}
                      name="stream"
                      label="Interested Stream"
                      size="small"
                      required
                      options={[
                        {
                          label: 'School of Computing',
                          value: 'school of computing',
                        },
                        {
                          label: 'School of Engineering',
                          value: 'school of engineering',
                        },
                        {
                          label: 'School of Sciences',
                          value: 'school of sciences',
                        },
                      ]}
                    />
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ width: '30%' }}>
                    <FormSingleSelect
                      name="courseId"
                      label="Program"
                      size="small"
                      required
                      control={control}
                      options={
                        course?.length > 0
                          ? course?.map((item) => ({
                              label: item?.courseName,
                              value: item?.id,
                            }))
                          : []
                      }
                    />
                  </Box>

                  <Box sx={{ width: '70%' }}>
                    <FormSingleSelect
                      name="programId"
                      label="Courses"
                      required
                      size="small"
                      control={control}
                      options={
                        courseId
                          ? programData?.map((item) => {
                              return {
                                label: item?.branchName,
                                value: item?.id,
                              }
                            })
                          : []
                      }
                    />
                  </Box>
                </Box>

                <FormTextField
                  control={control}
                  name="password"
                  label="Password"
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box>
                  <FormSingleCheckbox
                    control={control}
                    name="terms"
                    label={'Terms and Conditions'}
                    required
                  />
                </Box>

                {/* <ReCAPTCHA
                  sitekey={
                    process.env.SITE_KEY ||
                    '6Lcme2UlAAAAALHCk6SVmYlgZv54PPEIfKAT8oIP'
                  }
                  onChange={handleCaptchaChange}
                /> */}
                <LoadingButton
                  type="submit"
                  // disabled={isDevelopment ? false : !captchaResponse}
                  fullWidth
                  loading={loading}
                  variant="contained"
                >
                  Register
                </LoadingButton>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    sx={{ cursor: 'pointer', padding: '0', margin: '0' }}
                    onClick={() => {
                      window.open(
                        'https://forms.gle/aVg1ojRuVWS6MJyf7',
                        '_blank',
                      )
                    }}
                    variant="text"
                  >
                    {' '}
                    PHD applicants Click here to apply {'  '}
                    <OpenInNewIcon sx={{ paddingLeft: '3px' }} />
                  </Button>
                </div>
              </Stack>
            </form>
          </>
        )}

        {error && (
          <Alert severity="error" sx={{ marginTop: '20px' }}>
            {error}
          </Alert>
        )}
      </Box>
    </Box>
  )
}

export default RegisterPage
