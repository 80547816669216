import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { StyledInstructions, StyledPointBox, StyledPoints } from './styles'

const instructions: any = [
  {
    text: 'The online application is specifically for admission to the programs offered at Godavari Global University (GGU) for the year 2024.',
  },
  { text: 'The application form fee is non-refundable.' },
  {
    text: 'The email address provided during registration will serve as the primary mode of communication until enrolment is completed. Changes to the email address will not be allowed under any circumstances.',
  },
  // {
  //   heading: 'Giet University Query Management System:',
  //   text: 'Applicants are strongly advised to use Giet University Query Management System (Giet University-QMS), rather than emailing, to get a quick response.',
  // },
  // {
  //   text: 'Register and verify your email ID',
  // },
  // {
  //   text: 'Click on [Any Queries? Ask US]in your dashboard',
  // },
  // {
  //   text: 'Select query category and submit your query',
  // },
]

function Instructions() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.8 }}
      transition={{ duration: 0.5 }}
    >
      <StyledInstructions>
        <Typography variant="h6" sx={{ fontSize: '40px' }}>
          Instructions
        </Typography>
        {instructions.map((instruction) => (
          <>
            <Typography
              sx={{ fontSize: '16px', margin: '10px 0px' }}
              variant="h6"
            >
              {instruction?.heading}
            </Typography>
            <StyledPointBox>
              <StyledPoints />
              <Typography sx={{ fontSize: '15px' }}>
                {instruction.text}
              </Typography>
            </StyledPointBox>
          </>
        ))}
      </StyledInstructions>
    </motion.div>
  )
}

export default Instructions
