import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import {
  StyledApplication,
  StyledKeyPoints,
  StyledPointBox,
  StyledPoints,
} from './styles'

const keyPoints = [
  {
    point:
      'The GGUCET-2024 online application form is designated for admission into various undergraduate programs.',
  },
  // {
  //   point: 'Giet University application : Fee Rs. 500/-',
  // },
  {
    point: 'NRI/PIO/OCI candidates are also eligible to apply.',
  },
  {
    point:
      'The selection of program preferences serves statistical purposes only. The final decision regarding program choices will be made at the time of admission.',
  },
  {
    point:
      'Dates for the entrance exam, counselling, and course commencement will be periodically announced by the university.',
  },
  {
    point:
      'Admission to GGU courses is not guaranteed by the submission of an application or payment of the application fee. The Admissions Committee holds exclusive authority to grant admission based on merit and a personal interview with the applicant.',
  },
]

function ApplicationForm() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5 }}
    >
      <StyledApplication>
        <StyledKeyPoints>
          <Typography variant="h6" sx={{ fontSize: '32px' }}>
            Key Points
          </Typography>
          {keyPoints.map((point, index) => (
            <StyledPointBox key={index}>
              <StyledPoints />
              <Typography sx={{ fontSize: '15px' }}>{point.point}</Typography>
            </StyledPointBox>
          ))}
        </StyledKeyPoints>
      </StyledApplication>
    </motion.div>
  )
}

export default ApplicationForm
