import { Box, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { StyledEligibility, StyledPointBox, StyledPoints } from './styles'

const criteria = [
  {
    name: 'For Undergraduate Programs:',
    points: [
      'Candidates must have successfully passed their 10+2 (or equivalent) examination in relevant subjects.',
      'A minimum of 60% marks in the qualifying examination at the 10+2/Intermediate level is required for admission. Admission may be through EAPCET/GGUCET-2024 conducted by the University, JEE on Merit, or Qualifying Exam on merit.',
    ],
  },
  {
    name: 'For Post graduate M.Tech Program',
    points: [
      'Candidates should possess a Bachelor’s degree in engineering or a related field with a minimum aggregate of 60% marks or an equivalent CGPA.',
      'A valid GATE score/PG-GGUCET-2024 is required.',
      'Candidates must have scored at least 60% marks in the qualifying examination.',
    ],
  },
  {
    name: 'For Post graduate MCA Program',
    points: [
      'Candidates must have completed Graduation in BCA or equivalent from a recognized institution',
      'A minimum aggregate of 60% in the qualifying examination (three-year B.Sc. or Bachelors of Computer Science and Engineering/equivalent degree in relevant subjects) is necessary.',
      'A valid GATE score/PG-GGUCET-2024 is required.',
    ],
  },
  {
    name: 'For BCA Program',
    points: [
      'Eligibility criteria for BCA admission include obtaining 50% marks in 10+2 or equivalent examination with computer application/science as an additional or core subject.',
      'Candidates who have passed the qualifying exam should appear in the entrance test conducted by GGU ',
    ],
  },
]

function Eligibility() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5 }}
    >
      <StyledEligibility>
        <Typography variant="h6" sx={{ fontSize: '40px' }}>
          Eligibility Criteria
        </Typography>
        <Typography sx={{ fontSize: '15px' }}>
          The eligibility criteria for programs offered at GGU are as listed
          below:
        </Typography>
        {criteria.map((item, index) => (
          <Box key={index}>
            <Typography variant="h6" sx={{ paddingY: '10px' }}>
              {item.name}
            </Typography>
            {item.points.map((point, index) => (
              <StyledPointBox key={index}>
                <StyledPoints />
                <Typography sx={{ fontSize: '15px' }}>{point}</Typography>
              </StyledPointBox>
            ))}
          </Box>
        ))}
      </StyledEligibility>
    </motion.div>
  )
}

export default Eligibility
